import React from 'react';
import Layout from '../components/Layout/Layout';
import './history.scss';
import { Row, Col, Container } from 'reactstrap';
import HistoryImage from '../components/images/header2.png';

const History = () => {
    return (
        <Layout>

            <Container className='px-0 mb-5' fluid={true}>
                <div className='Jumbotron history'>
                    <div className="innerContent text-center">
                        <h1 className="display-5 pb-2 text-white">Alauna Lodge 6266 History</h1>

                    </div>
                </div>
            </Container>


            <Container className='mb-5'>
                <Row>
                    <Col lg={5}>
                        <img src={HistoryImage} alt="" className='w-100' />
                    </Col>
                    <Col lg={7}>

                        <p>For more than 150 years Alcester had just one lodge Apollo Lodge, founded to much fanfare in 1794.

                            By the 1940s there was strong demand for Freemasonry in Alcester which Apollo was unable to accommodate. It was decided a second lodge was needed to avoid men having to travel out of the town to enjoy Freemasonry.<br /><br />

                            As a result 11 would be founders met with the master, treasurer and secretary of Apollo Lodge at the foot of the winding staircase in The Swan Hotel in Alcester on July 18, 1945. Coming just two months after the end of the Second World War in Europe, it was fitting such efforts were being made to found a branch of an organisation based on brotherly love, relief and truth.<br /><br />

                            It was decided the new lodge should be called Alauna, after the ancient name given to the settlement which stood in Roman times on the site the town is now based on.<br /><br />

                            The first challenge facing the infant lodge was the fact that while members of Apollo were pleased to sponsor the creation of Alauna they did not want to be among the founders.<br /><br />

                            As a result Alauna was not able to use any of the antique furniture or share the traditions established by the older lodge. However this turned out to be a blessing in disguise as it helped Alauna establish its own traditions which make it the unique lodge it is today.<br /><br />

                            The first master of the lodge was Worshipful Brother J Ebor Haynes and Alauna was officially consecrated on May 27, 1946 at the Edgbaston Assembly Rooms in the presence of the Provincial Grand Master of Warwickshire and 92 brethren. A collection was held on the night which raised £9 12s.<br /><br />

                            Alauna Lodge met for the first regular meeting on September 3, 1946 at Alcester Town Hall which has remained its home ever since. The first charity collection raised £3 and the cost of subscribing to join was £4 4s which included seven meals, which were enjoyed at The Swan Hotel until 1970.<br /><br />

                            Men from numerous professions have joined Alauna, with the main ones being teachers, police officers and politicians.<br /><br />

                            In October 1982 the lodge held its 250th meeting and on 12 March 2019 the lodge held its 500th meeting.<br /><br />

                            During the coronavirus pandemic the lodge continued to meet virtually in accordance with the restrictions in place at the time and marked its 75th anniversary in May 2021 over Zoom.<br /><br />
                            The lodge also continued to carry out its charitable work providing support to local programmes to provide food to local families in need as a result of the impact of the pandemic.<br /><br />

                            Alauna was twinned with St Augustine’s Lodge as part of a scheme initiated by the Provincial Grand Master of Warwickshire in 1990 and exchange visits between the two lodges continue until this day.<br /><br />

                            More than 100 men have been initiated into the lodge over the decades and more and more are attracted to this ancient hobby.<br /><br />

                        </p>
                    </Col>

                </Row>
            </Container>

        </Layout>
    )
}

export default History