import React from 'react';
import Header from '../header/header';
import Footer from '../footer/Footer';


export default function Layout(props) {
    return (
        <>
            <Header />
            <div className={props.class}>
                {props.children}
            </div>
            <Footer />
        </>

    )
}
