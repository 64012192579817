import React from 'react';
import Layout from '../components/Layout/Layout';

const PrivacyPolicy = () => {
    return (
        <Layout>

            <p>
                <strong>1. The information we collect and how we use it</strong>
                <br /><br />
                When you register for our client service we need to know your name and e-mail address. We store this information to allow us to process your registration, create your account, provide and maintain the website service as well as to communicate with you on any matter relating to the conduct of your account and the provision of the service in general. Any personal information you post on the website you create will also be processed in order to provide the website service.
                <br /><br />
                We may also use aggregate information and statistics for the purposes of monitoring website usage in order to help us develop the website and our services and may provide such aggregate information to third parties. These statistics will not include information that can be used to identify any individual.
                <br /><br />
                <strong>2. Our use of cookies and other information storage technologies</strong>
                <br /><br />
                A cookie is a text-only string of information that a website transfers to the file of the browser on your computer's hard disk so that the website can remember who you are.
                <br /><br />
                A cookie will typically contain the name of the domain from which the cookie has come, the "lifetime" of the cookie, and a value, usually a randomly generated unique number.
                <br /><br />
                When you visit our website we send you a cookie. Cookies may be used in the following ways:
                <br /><br />
                To help us recognise you, so that you do not have to re-key your registration details.
                <br /><br />
                To help us recognise you as a unique visitor (just a number) when you return to our website and to allow us to tailor content or advertisements to match your preferred interests or to avoid showing you the same adverts repeatedly.
                <br /><br />
                To compile anonymous, aggregated statistics that allow us to understand how users use our site and to help us improve the structure of our website. We cannot identify you personally in this way.
                <br /><br />
                Two types of cookies may be used on this website: (1) session cookies, which are temporary cookies that remain in the cookie file of your browser until you leave the site; and (2) persistent cookies, which remain in the cookie file of your browser for much longer (though how long will depend on the lifetime of the specific cookie).
                <br /><br />
                <strong>Disabling/Enabling Cookies</strong>
                <br /><br />
                You have the ability to accept or decline cookies by modifying the settings in your browser. However, you may not be able to use all the interactive features of our site if cookies are disabled. In order to block or delete cookies, select the "Contents &amp; Index" tab under the "Help" menu of your internet browser and type in "delete cookies" or "block cookies" to find a step by step process of how to undertake such actions.
                <br /><br />
                <strong>3. Where we process your personal data</strong>
                <br /><br />
                Although we process your data within the European Economic Area ("EEA"), owing to the global nature of the Internet infrastructure, the information you provide may be transferred in transit to other countries outside the EEA that do not have similar protections in place as the EEA regarding your data and its use as set out in this policy. By submitting your information you consent to such transfers outside the EEA.
                <br /><br />
                <strong>4. How we protect your information</strong>
                <br /><br />
                The internet is not a secure medium. However we have put in place various security procedures to protect your information.
                <br /><br />
                <strong>5. Sale of business</strong>
                <br /><br />
                If this business is sold or integrated with another business your details may be disclosed to our advisers and any prospective purchasers and their advisers and will be passed on to the new owners of the business. You will be informed of any change in ownership in our business.
                <br /><br />
                <strong>6. Your consent</strong>
                <br /><br />
                By submitting your information you consent to the use of that information as set out in this policy. If we change our privacy policy we will post the changes on this page, and may place notices on other pages of the website, so that you may be aware of the information we collect and how we use it at all times. We will also e-mail you should we make any changes to the way in which we use your information so that you may consent to our use of your information in that way. Continued use of the service will signify that you agree to any such changes.
                <br /><br />
                <strong>7. How to contact us</strong>
                <br /><br />
                We welcome your views about our website and our privacy policy. If you would like to contact us with any queries or comments please use our Contact Us form, or write to the Customer Service Department at Alauna Lodge 6266.
                <br /><br />
                For further information please call us on&nbsp;01527 458 016
            </p>


        </Layout>
    )
}

export default PrivacyPolicy