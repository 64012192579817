import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


import Home from './Pages/Home';
import About from './Pages/About';
import History from './Pages/History';
import FAQ from './Pages/FAQ';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Contact from './Pages/Contact';

function App() {
  return (
    <div className="App">      
 

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/history" element={<History />} />
          <Route path="*" element={<Home />} />
        </Routes>       
      </Router>
    </div>
  );
}

export default App;
