import React from 'react';
import Layout from '../components/Layout/Layout';
import './home.scss';
import {
    Row,
    Col,
    Container
} from 'reactstrap';
import { Link } from 'react-router-dom';

import AlcesterStreet from '../components/images/homepage.png';

const Home = () => {
    return (
        <Layout>

            <div className='Jumbotron home'>
                <div className="innerContent">
                    <h1 className="display-5 pb-2 text-white">Welcome to Alauna Lodge 6266</h1>
                    <p className="lead text-white">We hope you will find the history of our lodge, Alauna 6266 and the brief introduction of Freemasonry both interesting and informative.</p>

                    <p className="lead pb-4">
                        <Link className='btn btn-primary' to="/about" color="primary">About Freemasonry</Link>
                    </p>
                </div>
            </div>

            <Container>

                <Row className="gx-4 gx-lg-5 align-items-center my-5">
                    <Col lg={5}><img className="img-fluid rounded mb-4 mb-lg-0" src={AlcesterStreet} alt="..." /></Col>
                    <Col lg={7}>
                        <h1 className="font-weight-light">Alcester and Alauna 6266</h1>
                        <p>Alcester is an old market town of Roman origin at the junction of the River Alne and the River Arrow in Warwickshire, England. It is situated approximately 8 miles (13 km) west of Stratford-upon-Avon and 8 miles south of Redditch, close to the Worcestershire border.</p>
                        <p>In Roman times Alcester (Alauna) was a walled town and Roman fort of some importance being located at a junction between the Ryknild Street Roman  road, the ancient Saltway from Droitwich, the Roman road from Stratford upon Avon and the Fosse Way</p>
                        <p>FOR more than 150 years Alcester had been served by a single lodge of Freemasons Apollo, founded to much fanfare in 1794.</p>
                        <Link className="btn btn-primary" to="/history">Read More</Link>
                    </Col>
                </Row>

                <div className="card text-white bg-secondary my-5 py-2 text-center">
                    <div className="card-body">
                        <h3 className='title text-white'>Did you know?</h3>
                        <p className="text-white m-0">Charity is in a Freemason’s DNA. It is the focus of our First Degree ceremony and its importance and virtues are the first lesson a newly made Freemason learns.</p></div>
                </div>

                <section className='ourValues'>
                    <div className="row gx-4 gx-lg-5">
                        <div className="col-md-3 mb-5">
                            <div className="card h-100">
                                <div className="card-header">
                                    <h2 className="card-title">Integrity</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">Rather than working on buildings like the masons of old, today’s Freemasons focus on building themselves as people of integrity, and membership provides the structure to help achieve that goal.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-5">
                            <div className="card h-100">
                                <div className="card-header">
                                    <h2 className="card-title">Friendship</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">One of the oldest social organisations in the world, Freemasonry is not defined by an ideology. It is open to people from all religions and political persuasions and provides the common foundation for friendships between members, many of which will last for life.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-5">
                            <div className="card h-100">
                                <div className="card-header">
                                    <h2 className="card-title">Respect</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">With a membership of more than 150,000 people drawn from communities across the UK, Freemasonry brings people together irrespective of their race, religion or any other perceived differences that can divide us as a society.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-5">
                            <div className="card h-100">
                                <div className="card-header">

                                    <h2 className="card-title">Charity</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">Kindness and charitable giving are deeply ingrained within the principles of Freemasonry. The organisation provides a support structure that helps members make positive contributions to communities and worthwhile causes through fundraising events or volunteer work.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>

        </Layout >
    )
}

export default Home