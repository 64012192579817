import React, { useState } from 'react';
import './header.scss';
import {
    Row,
    Col,
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Container
} from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../images/logo.png';

const Header = () => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Container className='header py-1' fluid={true}>
            <Container>

                <Row>
                    <Col md={12}>
                        <Navbar sticky="top" expand="sm">
                            <Link className='logo' to="/home"><img src={Logo} alt="Logo" /></Link>
                            <div className="flex-grow-1"></div>
                            <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
                            <Collapse isOpen={isOpen} navbar>
                                <Nav className="ml-auto" navbar>
                                    <NavItem><NavLink exact to="/home" activeclassName="active-link">Home</NavLink></NavItem>
                                    <NavItem><NavLink to="/history" activeclassName="active-link">History</NavLink></NavItem>
                                    <NavItem><NavLink to="/about" activeclassName="active-link">About</NavLink></NavItem>
                                    <NavItem><NavLink to="/faq" activeclassName="active-link">FAQs</NavLink></NavItem>
                                    <NavItem><NavLink to="/contact" activeclassName="active-link">Contact</NavLink></NavItem>
                                </Nav>
                            </Collapse>

                        </Navbar>
                    </Col>
                </Row>



            </Container >
        </Container >
    )
}

export default Header