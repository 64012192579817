import React from 'react'
import { Link } from 'react-router-dom';
import './footer.scss';
import Charter from '../images/footer_logo.png';
import ApprovedLogo from '../images/chartermark.png';
import { ExternalLink } from 'react-external-link';

const Footer = () => {
    return (
        <div>
            <footer className="w-100 flex-shrink-0 ">
                <div className="container py-2">
                    <div className="row  d-flex align-items-center">
                        <div className="col-md-6 ">
                            <p className="small text-muted mb-0">&copy; Copyright 2015-{new Date().getFullYear()}. All rights reserved. <Link to="/privacypolicy">View Privacy Policy</Link></p>
                        </div>

                        <div className="col-md-6">
                            <ExternalLink href="https://www.warwickshirefreemasons.org.uk">
                                <img className='float-end' src={Charter} alt="" style={{ width: '3rem' }} />
                            </ExternalLink>
                            <ExternalLink href="https://www.warwickshirefreemasons.org.uk">
                                <img className='float-end' src={ApprovedLogo} alt="" style={{ width: '3rem', marginTop: '-5px', marginRight: '20px' }} />
                            </ExternalLink>
                        </div>
                    </div>
                </div >
            </footer >

            <p className='text-center pt-3' style={{ fontSize: 13 }}><i>Please note: We cannot guarantee that any link or feed from this site has been approved by the United Grand Lodge of England or the Provincial Grand Lodge of Warwickshire. <br /> Neither can we guarantee that any subsequent link from their pages are recognised by, or have the approval of the United Grand Lodge of England or the Provincial Grand Lodge of Warwickshire.</i></p>
        </div >
    )
}

export default Footer