import React from 'react';
import Layout from '../components/Layout/Layout';
import { Row, Col, Container, UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import './faq.scss';

import { ExternalLink } from 'react-external-link';
//import HistoryImage from '../components/images/header2.png';

const FAQ = () => {
    return (
        <Layout>
            <Container className='px-0 mb-5' fluid={true}>
                <div className='Jumbotron faq'>
                    <div className="innerContent text-center">
                        <h1 className="display-5 pb-2 text-white">Alauna Lodge FAQs</h1>

                    </div>
                </div>
            </Container>

            <Container className='mb-5'>
                <Row>
                    <Col lg={12}>
                        <UncontrolledAccordion
                            defaultOpen={[
                                '1'
                            ]}
                            stayOpen
                        >
                            <AccordionItem>
                                <AccordionHeader targetId="1">
                                    What is Freemasonry?
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                    <p>This is usually the first question that is asked by those who, like you, have shown an interest in the subject. This website is designed to answer this and many other questions. It will also point you in the right direction if you wish to find out more. It has been designed to lay to rest the myths and fictions so often published by the critics and detractors of Freemasonry that its members only look after themselves, and that they conceal their membership. Please continue to browse and discover the high principles and moral standards by which Freemasons live, the history of the order, its structure and organisation, and examples of its involvement in the wider community.</p>
                                    <p>So, what is the answer to the question? As well as being one of the world's oldest secular fraternal societies, Freemasonry is an enjoyable association of like - minded men who work towards a common goal of being happy themselves and communicating happiness to others. This is achieved by work in the Lodge, largely based on a series of ritual ceremonies, with much in common with the old passion plays, which follow ancient forms and use stonemasons' customs and tools as allegorical guides. These seek to instil morality in all men: by teaching and practising high standards of conduct in society. These aims are furthered at the dinners held after lodge meetings and at other social occasions.</p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="2">
                                    Who can be a Freemason?
                                </AccordionHeader>
                                <AccordionBody accordionId="2">
                                    <p>Any man who is at least 21, is law-abiding, of good character and believes in God, can become a Freemason. The order is not restricted to Protestants: it is open to all men of all faiths, such as Jews, Muslims, Hindus and Sikhs. Contrary to popular belief, the Order has many Roman Catholics in its membership.</p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="3">
                                    Is religion relevant to Freemasonry?
                                </AccordionHeader>
                                <AccordionBody accordionId="3">
                                    <p>Freemasonry is secular, ie non-religious, but it encourages all its members, who are required to believe in God, to follow their own faith and to be active in their own Churches or other places of worship. Lodge meetings are opened and closed with prayers, as are the daily sessions of the House of Commons, but discussion of religion, and indeed politics, is prohibited at Lodge meetings.</p>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="4">
                                    Does Freemasonry have anything to hide?
                                </AccordionHeader>
                                <AccordionBody accordionId="4">
                                    <p>Definitely not! If it were, this web site would not exist and be available for public view world-wide. Most people know where the meeting places are, the ritual of the ceremonies and the many books written about Freemasonry can be read in public libraries. Members are encouraged to speak openly about their membership. Like many other organisations, though, meetings of Lodges are held in private.</p>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="5">
                                    Do Freemasons swear oaths?
                                </AccordionHeader>
                                <AccordionBody accordionId="5">
                                    <p>Freemasons make solemn promises concerning their conduct in the Lodge and in society. They also promise to keep confidential the traditional methods of recognition, which are only used within a Lodge or when visiting a Lodge where the Mason is not known. They should not be used outside a Lodge or disclosed to the public.</p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="6">
                                    Is it true that Freemasons only help themselves?
                                </AccordionHeader>
                                <AccordionBody accordionId="6">
                                    <p>No. Members must never use their Freemasonry as a means of gaining preferment for themselves or any other person. Any attempt to do so could, and does, result in expulsion from membership. Freemasons take an active role in the community, such as voluntary work, and donate substantial sums of money each year from their own pockets to many charitable causes not connected with Freemasonry. The Masonic Province of Warwickshire donates about £90,000 each year to non-Masonic charities, whilst individual Freemasons and lodges also donate substantial sums.</p>

                                </AccordionBody>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionHeader targetId="7">
                                    Are there many Freemasons?
                                </AccordionHeader>
                                <AccordionBody accordionId="7">
                                    <p>There are about 250,000 Freemasons in England and Wales, with some 4,500 in this Province. The Grand Lodge of England is the supreme authority for Freemasonry in the two countries, which are divided into 47 Provinces, of which Warwickshire is one, and a Metropolitan District in London. Grand Lodge is presided over by the Grand Master, currently HRH the Duke of Kent, and head of a Province is the Provincial Grand Master. The Provincial Grand Master for Warwickshire at present is Philip L Hall. The basic unit in Freemasonry is the Lodge, whose head is the Worshipful Master, elected each year by the members, and assisted by a team of officers including Wardens, a Treasurer and a Secretary. There are nearly 200 Lodges in this Province and 24 Masonic meeting places.</p>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="8">
                                    When did Freemasonry start?
                                </AccordionHeader>
                                <AccordionBody accordionId="8">
                                    <p>No one knows. Some say that we evolved from the operative stonemasons who built the cathedrals and castles, and held their own Lodges to discuss trade affairs. In the 1600s non-operatives were invited to join, and formed the basis of Masonic Lodges. Others say that groups of right thinking men got together at about the same time and taught morality by allegory and symbolism, using the story of King Solomon and the building of the first Temple at Jerusalem to illustrate and explain the lessons of morality. These lessons were taught by means of morality plays, and they continue more or less in this form today in our Lodges.</p>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="9">
                                    So what happens at a Lodge meeting?
                                </AccordionHeader>
                                <AccordionBody accordionId="9">
                                    <p>Like other organisations there is a certain amount of administration to attend to such as minutes of the last meeting, which have to be approved, balloting for new members, financial matters (subscriptions and expenditure, for example), electing new officers, and dealing with correspondence. The ceremonies for making and progressing new Masons take up a fair proportion of the time, although these do not happen at every meeting. Quite often the members will be given a lecture on an issue of Masonic interest, to increase their knowledge of the fascinating history and meaning of Freemasonry. An important ceremony that does take place every year in every Lodge is the Installation of the new Master, who then appoints the Officers of the Lodge who will assist him with the ceremonial and administrative work for the year that he is in office. Most Lodges meet formally six or seven times a year, in addition to which are management meetings and rehearsals, together with a variety of social activities, many of which include members' partners and families.</p>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="10">
                                    What do Freemasons aim for?
                                </AccordionHeader>
                                <AccordionBody accordionId="10">
                                    <p>They strive to be good citizens, to practice the highest moral and social standards, and to be men of friendship, charitable disposition, and integrity. It is often said that Freemasonry makes good men better.</p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="11">
                                    Can women be Freemasons?
                                </AccordionHeader>
                                <AccordionBody accordionId="11">

                                    <p>There are two Grand Lodges in this country solely for women, but our Grand Lodge does not admit them. We are not allowed to visit their Lodges.</p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="12">
                                    Isn't Masonic ritual out of place in modern society?
                                </AccordionHeader>
                                <AccordionBody accordionId="12">
                                    <p>No. The ritual is a shared experience , which binds the members together. Its use of drama, allegory and symbolism impresses the principles and teachings more firmly in the mind of each candidate than if they were simply passed on to him in matter-of-fact modern language.</p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="13">
                                    Why do you call it the Volume of the Sacred Law and not the Bible?
                                </AccordionHeader>
                                <AccordionBody accordionId="13">
                                    <p>To the majority of Freemasons the volume of the Sacred Law is the Bible. There are many in Freemasonry, however, who are not Christian and to them the Bible is not their sacred book and they will make their promises on the book which is regarded as sacred to their religion. The Bible is always present in an English Lodge but as the organisation welcomes men of many different faiths, it is called the Volume of the Sacred Law. Thus, when the Volume of the Sacred Law is referred to in ceremonies, to a non-Christian it will be the holy book of his religion and to a Christian it will be the Bible. The VSL must always be open when the Lodge is open.</p>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="14">
                                    Why do you call God the Great Architect?
                                </AccordionHeader>
                                <AccordionBody accordionId="14">
                                    <p>Freemasonry embraces all men who believe in God. Its membership includes Christians, Jews, Hindus, Sikhs, Muslims, Parsees and others. The use of descriptions such as the Great Architect prevents disharmony. The Great Architect is not a Masonic god nor an attempt to combine all gods into one. Thus, men of differing religions pray together without offence being given to any of them.</p>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="15">
                                    Is Freemasonry an international Order?
                                </AccordionHeader>
                                <AccordionBody accordionId="15">
                                    <p>Only in the sense that Freemasonry exists throughout the free world. Each Grand Lodge is sovereign and independent, and whilst following the same basic principles, may have differing ways of passing them on. There is no international governing body of Freemasonry.</p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="16">
                                    Why do you wear regalia?
                                </AccordionHeader>
                                <AccordionBody accordionId="16">
                                    <p>We have borrowed it from our ancient forebears the operative stonemasons as we have some of their working tools. Wearing regalia is historical and symbolic and, like a uniform, serves to indicate to members where they rank in the organisation.</p>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="17">
                                    How many degrees are there in Freemasonry?
                                </AccordionHeader>
                                <AccordionBody accordionId="17">
                                    <p>Basic Freemasonry consists of the three 'Craft' degrees (Entered Apprentice, Fellow Craft and Master Mason), which may be completed by the Royal Arch (Chapter). (It is not compulsory to be a Royal Arch Mason). There are many other Masonic degrees and Orders which are called 'additional' because they add to the basis of the Craft and Royal Arch. They are not basic to Freemasonry but add to it by further expounding and illustrating the principles stated in the Craft and Royal Arch.</p>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="18">
                                    How much does it cost to become a Freemason?
                                </AccordionHeader>
                                <AccordionBody accordionId="18">
                                    <p>It varies from Lodge to Lodge but anyone wishing to join can find a Lodge to suit his pocket. On entry, there is an initiation fee and an apron to buy. A member pays an annual subscription to his Lodge which covers his membership and the administrative cost of running the Lodge. It is usual to have a meal after the meeting; the cost of this can be included either in the annual subscription or paid for at the time. It is entirely up to the individual member what he gives to charity, but it should always be without detriment to his other responsibilities to his family and his business.</p>

                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="19">
                                    Would you like to know more?
                                </AccordionHeader>
                                <AccordionBody accordionId="19">
                                    <p>If you would like to know more about Freemasonry in Warwickshire then please visit <ExternalLink href="https://www.warwickshirefreemasons.org.uk">www.warwickshirefreemasons.org.uk</ExternalLink>, the website of the Provincial Grand Lodge of Warwickshire. You will find more information about what Freemasonry is, what we do and our charitable work.</p>
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>

                        <p className='pt-5'>Thank you for taking the time and interest to visit our site. We hope it helps you to learn something about Freemasonry and that you may wish to find out more.</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default FAQ