import React, { useRef, useState } from 'react';
import Layout from '../components/Layout/Layout';
import { Row, Col, FormGroup, Label, Input, FormFeedback, Container } from 'reactstrap';
import emailjs from '@emailjs/browser';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

    const form = useRef();

    const [emailName, setEmailName] = useState()

    const user_name = useRef(null)
    const user_number = useRef(null)
    const user_email = useRef(null)
    const user_message = useRef(null)

    const sendEmail = (e) => {
        e.preventDefault();

        toast.success(`Hi ${emailName}, thank you for your enquiry.`)

        emailjs.sendForm('service_yft4rjb', 'template_acoa3je', form.current, 'dkGqBxUBvLermc2S0')
            .then((result) => {
                console.log(result.text);
                console.log("message sent");
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset(); /*  Clear form after send */
    };


    return (
        <Layout>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container className='py-5'>
                <Row>
                    <Col lg={3}></Col>
                    <Col lg={6}>
                        <form ref={form} onSubmit={(e) => sendEmail(e)}>

                            <h2 class="pb-3 align-left mbr-fonts-style display-5">
                                Get in Touch
                            </h2>
                            <p>If you have any questions or are interested in joining Alauna Lodge then please contact our secretary by filling out the form below.</p>

                            <p>We look forward to hearing from you.</p>
                            <FormGroup>
                                <Label for="userFullName">
                                    Full Name
                                </Label>
                                <Input id='userFullName' type='text' name="user_name" onInput={(e) => setEmailName(e.target.value)} required />
                                <FormFeedback>
                                    Please enter your full name
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="userTelephoneNumber">
                                    Telephone Number
                                </Label>
                                <Input id='userTelephoneNumber' type='text' name="user_number" required />
                                <FormFeedback>
                                    Please enter your Telephone Number
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="userEmailAddress">
                                    Email Address
                                </Label>
                                <Input id='userEmailAddress' type='text' name="user_email" required />
                                <FormFeedback>
                                    Please enter your email  address
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="userHowToBeContacted" >
                                    How would you like to be contacted?
                                </Label>
                                <select className='form-control'
                                    id="userHowToBeContacted"
                                    name="user_contactedby"
                                    type="select"
                                >
                                    <option value="Email">
                                        Email
                                    </option>
                                    <option value="Telephone">
                                        Telephone
                                    </option>
                                </select>

                            </FormGroup>

                            <FormGroup>
                                <Label for="userMessage" >
                                    Message
                                </Label>
                                <textarea
                                    id="userMessage"
                                    name="user_message"
                                    type="textarea"
                                    className='form-control'
                                    rows="5"
                                />


                            </FormGroup>
                            <button className='btn btn-primary col-xs-12 btn-lg w-100'>Send Message</button>
                        </form>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Contact